<template>
    <div class="about">
        <div class="bigImg bgimg">
            <!-- <img src="../../assets/img/skin/bigimg1.jpg" alt=""> -->
        </div>
        <div class="main fixed"> <div class="left wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;">
        <div class="sideMenu">
            <div class="sideTit"> <a>
            <h3>关于商会 </h3>
            <p>gongshanglianjianjie </p>
            </a> </div>
            <div class="mobileTit"><a type="button">关于商会</a></div>
            <ul class="sideMenuNav">
                <li :class="item.on?'on':''" v-for="(item,index) in menuList" :key="index"  @click="cutmenu(index,item.id)"><i>&nbsp;</i><a href="javascript:;">{{item.name}}</a></li>
            </ul>
        </div>
        <app-config></app-config>
        
        </div>
 
    
    <!--left end-->
    
    <div class="right wow fadeInUp animated" style="visibility: visible; animation-name: fadeInUp;">
          <div class="path">
        <h3>商会介绍</h3>
        <div class="pathdiv"> <a href="/">主页</a> &gt; <span>&gt;</span><a href="">关于商会</a> &gt; <span>&gt;</span><a href="" class="on">商会介绍</a></div>
      </div>
          <div class="rightBox">
        <div class="content fixed">
              <div>
                  <div class="content">
                      <div v-html="article.content">
                          
                      </div>
                    </div>
                </div>
            </div>
        <!--content end--> 
      </div>
        </div>
    <!--right end--> 
    
  </div>
    </div>
</template>

<script>
import AppConfig from "@/components/ahshconfig"
    export default {
        components:{
            AppConfig
        },
        data() {
            return {
                menuList:'',
                article:'',
                id:'',
                contentFlag:false,
                configs:'',
            }
        },
        created(){
          this.id = this.$route.query.id
          this.getmenuList(this.id)
          let configs = window.localStorage.getItem('configs')
          this.configs = JSON.parse(configs)
        },
        methods:{
          getmenuList(id){
            this.$http.get('/api/ahsh/children_menu/'+id).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.menuList = data
                console.log(this.menuList)
                this.getArticle(data[0].id)
              }
            })
          },
          getArticle(id){
              let ajax = {
                  menu_id:id,
                  page:1,
                  page_size:10
              }
              this.$http.post('/api/ahsh/article_list',ajax).then(res=>{
              let {data,code} = res.data
              if (code == 0) {
                this.article = data.data
                console.log(this.article)
              }
            })
          },
          cutmenu(i,id){
            this.clearStyle()
            this.getArticle(id)
            this.contentFlag = false
            this.menuList[i].on = true
          },
          clearStyle(){
            let data = this.menuList
            for (let index = 0; index < data.length; index++) {
                data[index].on = false
            }
          },
        },
    }
</script>

<style lang="less" scoped>
    .about{
        .bgimg{
            background-image:url('../../assets/img/skin/bigimg1.jpg');
            // height: 100px;
        }
    }
</style>